import { useRouter } from 'next/router'
import { trackButtonClick } from '~/lib/utils/analytics/analytics'
import { useSubscriptionNavigation } from '~/lib/utils/navigation'
import { PrimaryActionButton } from './ActionButton'

type GetStartedButtonProps = {
  title: string
  subtitle?: string
  area: string
  onClick?: () => void
  disabled?: boolean
  showProgressIndicator?: boolean
}

export default function GetStartedButton({
  title,
  subtitle,
  area,
  onClick,
  disabled,
  showProgressIndicator,
}: GetStartedButtonProps) {
  const { showSubscribePage } = useSubscriptionNavigation()
  const { asPath } = useRouter()

  return (
    <PrimaryActionButton
      title={title}
      subtitle={subtitle}
      disabled={disabled}
      showProgressIndicator={showProgressIndicator}
      onClick={() => {
        trackButtonClick(title, area)
        if (onClick) {
          onClick()
        } else {
          showSubscribePage({
            returnTo: asPath,
          })
        }
      }}
    />
  )
}
