import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, MenuAlt1Icon } from '@heroicons/react/solid'
import { Fragment } from 'react'

import DropdownMenuItem from '../elements/dropdowns/DropdownMenuItem'

import { useAppContext } from '~/lib/state_management/context/appContext'
import { NavbarDetailGroup } from '~/lib/state_management/models/types/nav'
import { useLoginNavigation } from '~/lib/utils/navigation'
import { TertiaryActionButton } from '../elements/buttons/ActionButton'
import GetStartedButton from '../elements/buttons/GetStartedButton'

type NavDropdownType = {
  label?: string
  detailGroup: NavbarDetailGroup[]
  getStartedOnClick?: () => void
}

export default function NavDropdown({ label, detailGroup, getStartedOnClick }: NavDropdownType) {
  const appContext = useAppContext()
  const { showLoginPage } = useLoginNavigation()

  return (
    <div className="text-right">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center text-xs font-bold text-white focus:outline-none ">
            {label ? (
              <div className="flex flex-row items-center justify-center px-2.5 py-4">
                <span className="block w-full cursor-pointer whitespace-pre-wrap text-center text-sm font-bold text-white transition-opacity duration-500 md:text-lg">
                  {label}
                </span>
                <ChevronDownIcon className="h-9 w-9 text-white" aria-hidden="true" />
              </div>
            ) : (
              <MenuAlt1Icon className="h-7 w-6 rotate-180 text-white" aria-hidden="true" />
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 origin-top-right divide-none bg-black-alt/[0.95] text-xs shadow-lg ring-1 ring-black ring-opacity-5 hover:text-gray-500 focus:outline-none">
            {detailGroup.map((detailItem) => {
              return (
                <div className="flex flex-col" key={detailItem.id}>
                  {detailItem.header && (
                    <div className="py-3 pl-4 font-super-new uppercase text-sea-foam">
                      {detailItem.header}
                    </div>
                  )}
                  {detailItem.links.map((linkItem) => {
                    return (
                      <DropdownMenuItem
                        key={linkItem.title}
                        allowCheckmarks={false}
                        label={linkItem.title}
                        url={linkItem.link}
                        mobileOnly={linkItem.mobileOnly}
                        signedInOnly={linkItem.signedInOnly}
                      />
                    )
                  })}
                </div>
              )
            })}
            {!appContext.isLoggedIn && (
              <div className="flex w-full flex-col gap-3 px-2 py-3 md:hidden">
                <TertiaryActionButton onClick={() => showLoginPage()} title="Log In" />
                <GetStartedButton onClick={getStartedOnClick} title="Get Started" area="Navbar" />
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
