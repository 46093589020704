export function buttonLayout(title: string, subtitle?: string) {
  if (subtitle) {
    return (
      <div className="flex flex-col">
        <span>{title}</span>
        <span className="mt-1 text-sm font-normal">{subtitle}</span>
      </div>
    )
  }
  return <span>{title}</span>
}
