import Link from 'next/link'
import NavDropdown from './navDropdown'

import { NavbarDetailGroup } from '~/lib/state_management/models/types/nav'
import { useLoginNavigation } from '~/lib/utils/navigation'
import { useAppContext } from '../../lib/state_management/context/appContext'
import { NoBackgroundActionButton } from '../elements/buttons/ActionButton'
import GetStartedButton from '../elements/buttons/GetStartedButton'
import { NoBackgroundLinkButton } from '../elements/buttons/LinkButton'

type NavbarProps = {
  loginVisible?: boolean
  getStartedOnClick?: () => void
}

export default function Navbar({ loginVisible = true, getStartedOnClick }: NavbarProps) {
  const appContext = useAppContext()
  const { showLoginPage } = useLoginNavigation()

  const orgsDropdownItems: NavbarDetailGroup[] = [
    {
      id: 'Organizations Detail',
      links: [
        { title: 'Health Plans', link: 'https://classes.tinyhood.com/for-health-plans' },
        { title: 'Health Systems & Providers', link: '/for-healthcare' },
        { title: 'Employers', link: '/for-business' },
      ],
    },
  ]

  const exploreDropdownItems: NavbarDetailGroup[] = [
    {
      id: 'Parents Detail',
      header: 'For Parents',
      links: [
        { title: 'Browse Classes', link: '/classes' },
        { title: 'Group Sessions', link: '/sessions' },
        {
          title: 'Community',
          link: appContext.isLoggedIn ? '/webusers/community' : '/circle-community',
        },
        { title: 'About', link: '/about' },
        { title: 'Account', link: '/account', mobileOnly: true, signedInOnly: true },
      ],
    },
    {
      id: 'Organizations Detail',
      header: 'For Organizations',
      links: [
        { title: 'Health Plans', link: 'https://classes.tinyhood.com/for-health-plans' },
        { title: 'Health Systems & Providers', link: '/for-healthcare' },
        { title: 'Employers', link: '/for-business' },
      ],
    },
    {
      id: 'Partnerships',
      header: 'Partnerships',
      links: [{ title: 'Tinyhood 𝘅 Safety 1st', link: 'https://learn.tinyhood.com/safety1st/' }],
    },
  ]

  return (
    <nav className="dark-theme absolute inset-x-0 z-40 flex flex-row items-center justify-between p-5">
      {/* Tinyhood logo */}
      <Link href="/" className="py-6.2 md:py-3.5">
        <svg
          width="901"
          height="101"
          viewBox="0 0 901 101"
          xmlns="http://www.w3.org/2000/svg"
          className="h-2.5 w-auto md:h-3"
        >
          <g fillRule="nonzero">
            <path
              className="nav-fill"
              d="M31.6 99.03h21.67v-76.2h31.6V1.94H0v20.89h31.6zM115.46 1.94h21.67v97.09h-21.67zM173.98 1.94v97.09h21.67V38.46l48.46 60.57h18.76V1.94h-21.54v60.72L192.87 1.94zM319.09 1.94h-25.43l38.3 61.45v35.64h21.54V63.39l38.44-61.45h-25.56l-23.71 38.8zM444.27 1.94h-21.54v97.09h21.54V61.32h45.68v37.71h21.67V1.94h-21.67v39.4h-45.68zM595.31 0c-28.48 0-51.66 22.64-51.66 50.49s23.18 50.49 51.66 50.49c28.48 0 51.67-22.6 51.67-50.49S623.83 0 595.31 0Zm0 80.22c-16.209652-.297275-29.194679-13.522622-29.194679-29.735S579.100348 21.047275 595.31 20.75c16.209652.297275 29.194679 13.522622 29.194679 29.735S611.519652 79.922725 595.31 80.22ZM725.83 0c-28.49 0-51.67 22.64-51.67 50.49s23.22 50.49 51.67 50.49c28.45 0 51.7-22.6 51.7-50.49S754.35 0 725.83 0Zm0 80.22c-10.753268.197208-20.775175-5.426724-26.209544-14.70785-5.434369-9.281125-5.434369-20.773176 0-30.0543C705.054825 26.176723 715.076732 20.552791 725.83 20.75c16.209652.297275 29.194679 13.522622 29.194679 29.735S742.039652 79.922725 725.83 80.22ZM900.28 50.49c0-22.4-12-48.55-45.82-48.55h-44.9v97.09h44.9c27.83 0 45.82-19.05 45.82-48.54Zm-69.05 27.78V22.84h21.28c15.94 0 25.83 10.59 25.83 27.65s-9.89 27.78-25.83 27.78h-21.28Z"
            />
          </g>
        </svg>
      </Link>

      {/* Desktop Menu */}
      <ul className="hidden flex-row items-center gap-3 md:flex">
        {!appContext.isLoggedIn && (
          <li>
            <NavDropdown
              label={'For Organizations'}
              detailGroup={orgsDropdownItems}
              getStartedOnClick={getStartedOnClick}
            />
          </li>
        )}
        <li>
          <NavDropdown
            label={'Explore'}
            detailGroup={exploreDropdownItems}
            getStartedOnClick={getStartedOnClick}
          />
        </li>
        {!appContext.isLoggedIn ? (
          <>
            {loginVisible && (
              <>
                <li>
                  <NoBackgroundActionButton
                    onClick={() => showLoginPage({ subscribe: true })}
                    title={'Log In'}
                  />
                </li>
                <li>
                  <GetStartedButton onClick={getStartedOnClick} title="Get Started" area="Navbar" />
                </li>
              </>
            )}
          </>
        ) : (
          <>
            <li>
              <NoBackgroundLinkButton href={'/classes'} title={'Browse Classes'} />
            </li>
            <li>
              <NoBackgroundLinkButton href={'/account'} title={'My Account'} />
            </li>
          </>
        )}
      </ul>

      {/* Mobile Menu */}
      <ul className="flex flex-row items-center md:hidden">
        <li>
          <NavDropdown detailGroup={exploreDropdownItems} getStartedOnClick={getStartedOnClick} />
        </li>
      </ul>
    </nav>
  )
}
