import Link from 'next/link'
import { buttonLayout } from '~/lib/utils/button'

// Link buttons accept an href destination

type LinkButtonProps = {
  href: string
  title: string
  subtitle?: string
}

const buttonClasses =
  'block w-full rounded-md cursor-pointer whitespace-pre-wrap py-3.7 px-2.5 text-center text-sm font-bold md:py-4 md:text-lg'

// Primary buttons rendered in the brand red color
export function PrimaryLinkButton({ href, title, subtitle }: LinkButtonProps) {
  return (
    <Link
      href={href}
      className={`${buttonClasses} flex flex-row items-center justify-center bg-orange text-white transition-opacity duration-500 hover:opacity-90 disabled:cursor-default disabled:opacity-75 disabled:hover:bg-orange`}
    >
      {buttonLayout(title, subtitle)}
    </Link>
  )
}

// Secondary buttons render with a slight transparent white overlay
export function SecondaryLinkButton({ href, title, subtitle }: LinkButtonProps) {
  return (
    <Link
      href={href}
      className={`${buttonClasses} bg-white/[.38] text-white transition-opacity duration-500`}
    >
      {buttonLayout(title, subtitle)}
    </Link>
  )
}

// Tertiary buttons render with a dark background and outlined button shape
export function TertiaryLinkButton({ href, title, subtitle }: LinkButtonProps) {
  return (
    <Link
      href={href}
      className={`${buttonClasses} border-[0.5px] border-white text-white transition-colors duration-500 hover:bg-black-alt`}
    >
      {buttonLayout(title, subtitle)}
    </Link>
  )
}

// No background
export function NoBackgroundLinkButton({ href, title, subtitle }: LinkButtonProps) {
  return (
    <Link href={href} className={`text-white ${buttonClasses}`}>
      {buttonLayout(title, subtitle)}
    </Link>
  )
}
