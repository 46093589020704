import { AnalyticsBrowser } from '@segment/analytics-next'
import { useEffect, useState } from 'react'

// Vercel example code of using Segment with Next.js
// https://github.com/vercel/next.js/blob/canary/examples/with-segment-analytics-pages-router/

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_TOKEN!,
})

function getCurrentUserId() {
  return analytics.instance?.user().id() || null
}

function getCurrentAnonymousId() {
  return analytics.instance?.user().anonymousId() || null
}

// This hook returns the current Segment userId and anonymousId and _should_
// automatically update whenever those ids are set or change. Unfortunately
// Segment doesn't offer an API for this so we approximate it by checking for
// changes after each render (using the asynchronous analytics.user() API) and
// also after each call to 'identify' or 'reset'.
// https://github.com/segmentio/analytics-next/issues/924
export function useSegmentIds() {
  const [userId, setUserId] = useState(getCurrentUserId())
  const [anonymousId, setAnonymousId] = useState(getCurrentAnonymousId())

  useEffect(() => {
    let cancelled = false

    const updateIds = () => {
      if (cancelled) {
        return
      }

      const newUserId = getCurrentUserId()
      if (userId !== newUserId) {
        setUserId(newUserId)
      }

      const newAnonymousId = getCurrentAnonymousId()
      if (anonymousId !== newAnonymousId) {
        setAnonymousId(newAnonymousId)
      }
    }

    analytics.user().then(updateIds)
    analytics.on('identify', updateIds)
    analytics.on('reset', updateIds)

    return () => {
      cancelled = true
      analytics.off('identify', updateIds)
      analytics.off('reset', updateIds)
    }
  })

  return [userId, anonymousId]
}
