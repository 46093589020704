import { useRouter } from 'next/router'
import { ParsedUrlQueryInput } from 'querystring'

export const useLoginNavigation = () => {
  const router = useRouter()

  const showLoginPage = ({
    tab = 'login',
    email = null,
    returnTo = router.asPath,
    subscribe = false,
  }: {
    tab?: 'login' | 'signup'
    email?: string | null
    returnTo?: string
    subscribe?: boolean
  } = {}) => {
    const query: ParsedUrlQueryInput = { return_to: returnTo, tab }
    if (email) query['email'] = email
    if (subscribe) query['subscribe'] = true
    router.push({
      pathname: '/login',
      query,
    })
  }

  return {
    showLoginPage,
  }
}

export const useSignupNavigation = () => {
  const router = useRouter()

  const showSignupPage = ({
    returnTo = '/subscribe',
    email = null,
  }: { returnTo?: string; email?: string | null } = {}) => {
    const query: ParsedUrlQueryInput = { return_to: returnTo }
    if (email) {
      query['email'] = email
    }

    router.push({
      pathname: '/signup',
      query,
    })
  }

  return {
    showSignupPage,
  }
}

export const useSubscriptionNavigation = () => {
  const router = useRouter()

  const showSubscribePage = ({ returnTo }: { returnTo?: string } = {}) => {
    let query = {}
    if (returnTo) {
      query = { return_to: returnTo }
    }
    router.push({ pathname: '/subscribe', query })
  }

  return {
    showSubscribePage,
  }
}
